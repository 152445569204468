import { HttpData, HttpNoData } from '../../../Core'

export async function getSchedule(jwt, date) {
	const response = await HttpNoData(
		`/api/v1/workSchedules?date=${date}`,
		'GET',
		jwt
	)

	if (!response.statusSuccessful) console.log(response.error)

	return response.data
}

export async function getWorkStatuses(jwt) {
	const response = await HttpNoData(
		`/api/v1/WorkSchedules/statuses`,
		'GET',
		jwt
	)

	if (!response.statusSuccessful) console.log(response.error)

	return response.data
}

export async function getWorkerCategories(jwt) {
	const response = await HttpNoData(
		`/api/v0/WorkerCategories`,
		'GET',
		jwt
	)

	if (!response.statusSuccessful) console.log(response.error)

	return response.data
}

export async function putWorkerStatus(jwt, body) {
	const response = await HttpData(`/api/v1/WorkSchedules`, 'PUT', jwt, body)

	if (!response.statusSuccessful) console.log(response.error)

	return response.data
}

export async function postWorkSchedule(jwt, body) {
	const response = await HttpData(`/api/v1/workSchedules`, 'POST', jwt, body)

	if (!response.statusSuccessful) console.log(response.error)

	return response.data
}

export async function getWorkers(jwt) {
	const response = await HttpNoData(`/api/v1/Workers`, 'GET', jwt)

	if (!response.statusSuccessful) console.log(response.error)

	return response.data
}
