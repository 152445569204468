import React, { useEffect, useState } from 'react'
import { Alert, Tab, Table, Tabs } from 'react-bootstrap'
import { putWorkerStatus } from '../API'
import { checkOffDay, getDaysInPart, getWeekDay } from '../utils'
import RowsWorkerCategories from './RowsWorkerCategories'
import styles from './WorkScheduleTable.module.css';

const WorkScheduleTable = ({
	jwt,
	selectedDate,
	schedule,
	workers,
	workerCategories,
	statuses,
	isMobile,
	currentMonthDays,
}) => {
	const [localSchedule, setLocalSchedule] = useState(schedule)
	const [activeTab, setActiveTab] = useState(1);

	const getTabs = (totalDays, isMobile) => {
		const tabs = []
		const daysInPart = isMobile ? 4 : Math.ceil(totalDays / 2)
		const numParts = Math.ceil(totalDays / daysInPart)

		for (let i = 1; i <= numParts; i++) {
			const startDay = (i - 1) * daysInPart + 1
			const endDay = Math.min(i * daysInPart, totalDays)
			tabs.push({
				eventKey: i,
				title: `${startDay} - ${endDay}`,
			})
		}

		return tabs
	}

	useEffect(() => {
		setLocalSchedule(schedule)
	}, [schedule])

	const handleStatusChange = (workerId, day, status) => {
		if (localSchedule && localSchedule.length > 0) {
			const updatedSchedule = localSchedule.map(workerSchedule => {
				if (workerSchedule.workerId === workerId) {
					return {
						...workerSchedule,
						schedule: workerSchedule.schedule.map(d =>
							d.day === day ? { ...d, status } : d
						),
					}
				}
				return workerSchedule
			})
			setLocalSchedule(updatedSchedule)
			putWorkerStatus(jwt, {
				date: `${selectedDate.slice(0, 8)}${day < 10 ? `0${day}` : day}`,
				workerId: workerId,
				key: status,
			})
		}
	}

	return (
		<div>
			<Tabs
				activeKey={activeTab}
				onSelect={tab => setActiveTab(tab)}
			>
				{getTabs(currentMonthDays, isMobile).map((tab, i) => (
					<Tab key={i} eventKey={tab.eventKey} title={tab.title}>
						<Table bordered hover>
							<thead >
								<tr>
									<td>Работник</td>
									{getDaysInPart(tab.eventKey, currentMonthDays, isMobile).map((day, i) => (
										<td key={i} style={{backgroundColor: checkOffDay(new Date(selectedDate.split('-')[0], +selectedDate.split('-')[1] - 1, day).toJSON().split('T')[0]) ? '#28F72C': 'white'}}>
											<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
												<span>{getWeekDay(new Date(selectedDate.split('-')[0], +selectedDate.split('-')[1] - 1, day))}</span>
												<span>{day}</span>
											</div>
										</td>))}
								</tr>
							</thead>
							<tbody>
								{
									workerCategories && workerCategories.length > 0 ?
										workerCategories.map(c => <RowsWorkerCategories key={c.id}
											category={c}
											currentMonthDays={currentMonthDays}
											handleStatusChange={handleStatusChange}
											isMobile={isMobile}
											localSchedule={localSchedule}
											statuses={statuses}
											tab={tab}
											workers={workers.filter(f => f.categoryId == c.id)}
										/>)
										: <tr>
											<td colSpan={currentMonthDays}>
												<Alert variant='warning'>Не получилось загрузить категории сотрудников</Alert>
											</td>
										</tr>
								}
								{
									<RowsWorkerCategories
										category={{ id: 'noCategory', name: "Без категории" }}
										currentMonthDays={currentMonthDays}
										handleStatusChange={handleStatusChange}
										isMobile={isMobile}
										localSchedule={localSchedule}
										statuses={statuses}
										tab={tab}
										workers={workers.filter(f => workerCategories.findIndex(c => c.id == f.categoryId) == -1)}
									/>
								}
							</tbody>
						</Table>
					</Tab>
				))}
			</Tabs>
		</div>
	)
}

export default WorkScheduleTable
